import * as Types from '../declarations'
import {
	convertMetricToImperial,
	convertSimpleToFrench,
	countryIsoCodeToSlugName,
	sluggify,
} from './helper'

// @ts-ignore
import iso3311a2 from 'iso-country-lookup'

export const formatDistance = (
	distance: [] | [number] | [number, number],
	unit: string,
): string | undefined => {
	const convertMetricToImperial = (distance: number) =>
		Math.ceil(distance / 1.6).toLocaleString()

	if (distance.length === 1) {
		return unit === 'metric'
			? `${distance.toLocaleString()} km`
			: `${convertMetricToImperial(distance[0])} miles`
	}

	if (distance.length === 2) {
		const [num1, num2] = distance

		return unit === 'metric'
			? `${num1.toLocaleString()} - ${num2.toLocaleString()} km`
			: `${convertMetricToImperial(num1)} - ${convertMetricToImperial(
					num2,
			  )} miles`
	}

	return undefined
}

export const formatDifficulty = (
	difficulty: number[],
	// unit: string,
): string | undefined => {
	if (difficulty.length === 1) {
		return convertSimpleToFrench(difficulty[0]).abbreviation
	}

	if (difficulty.length > 1) {
		const sortedDifficulties = difficulty.sort((a, b) => a - b)
		const lowestDifficulty = sortedDifficulties[0]
		const highestDifficulty = sortedDifficulties[sortedDifficulties.length - 1]

		return `${convertSimpleToFrench(lowestDifficulty).abbreviation} - ${
			convertSimpleToFrench(highestDifficulty).abbreviation
		}`
	}

	return undefined
}

export const formatHeight = (
	height: [] | [number] | [number, number],
	unit: string,
) => {
	if (height.length === 1)
		return unit === 'metric'
			? `${height[0].toLocaleString()} m`
			: `${convertMetricToImperial(height[0])} feet`

	if (height.length === 2) {
		const [num1, num2] = height

		return unit === 'metric'
			? `${num1.toLocaleString()} - ${num2.toLocaleString()} m`
			: `${convertMetricToImperial(num1)} - ${convertMetricToImperial(
					num2,
			  )} feet`
	}

	return undefined
}

export const formatNumberOrRange = (
	value: number[],
	prepend?: string,
): string | undefined => {
	if (value.length === 1) {
		return `${!!prepend ? prepend : ''}${value[0].toLocaleString()}`
	}
	if (value.length === 2) {
		const [num1, num2] = value
		return `${!!prepend ? prepend : ''}${num1.toLocaleString()} - ${
			!!prepend ? prepend : ''
		}${num2.toLocaleString()}`
	}

	return undefined
}

export const getThumbPath = (thumbSlug: string, size: string) => {
	return `/thumbs/${thumbSlug.toLowerCase()}-${size}.jpg`
}

// todo: obsolete?
export const getTrekThumbPath = (
	trekSlug: string,
	thumbSlug: string,
	size: string,
) => {
	return `/thumbs/${trekSlug}/${thumbSlug}-${size}.jpg`
}

export const getUploadThumbPath = (uploadId: number, size: string) => {
	if (uploadId === -1) {
		return 'https://placeholder.pics/svg/945x325/DEDEDE/555555/%5Bplaceholder%20-%20no%20images%20yet%5D'
	}
	// return `/api/thumbs/${uploadId}-${size}.jpg`
	// return `https://${process.env.NEXT_PUBLIC_DIGITAL_OCEAN_SPACES_BUCKET_NAME}.nyc3.cdn.digitaloceanspaces.com/thumbs/${uploadId}-${size}.jpg`

	return `https://${process.env.NEXT_PUBLIC_CDN_BASE}.cdn.guidedpeaks.com/thumbs/${uploadId}-${size}.jpg`
}

export const countryISOsToLiteralSlugs = (isos: string[]) => {
	return isos
		.map((isoCode) =>
			sluggify(iso3311a2.getCountry(isoCode.toUpperCase()).toLowerCase()),
		)
		.join('-')
}

export const getBaseURL = (tripOrClimb: Types.App.DataTypes.TrekOrClimb) => {
	const protocol =
		process.env.NODE_ENV === 'production' ? 'https://' : 'http://'
	const domain = `guided${
		tripOrClimb === Types.App.DataTypes.TrekOrClimb.TREK ? 'trekking' : 'peaks'
	}`
	const tld = process.env.NODE_ENV === 'production' ? 'com' : 'local:3800'

	return `${protocol}www.${domain}.${tld}`
}

export const getTrekRoute = (
	trekOrClimb: Types.App.DataTypes.TrekOrClimb,
	continent: string,
	countries: string[],
	slug: string,
): string => {
	const activitySegment = `/${trekOrClimb === 'TREK' ? 'trek' : 'climbing'}`
	const continentSegment = !!continent
		? '/' + sluggify(continent)
		: '/no-continent'
	const countrySegment = '/' + countryISOsToLiteralSlugs(countries)
	const slugSegment = '/' + slug

	return activitySegment + continentSegment + countrySegment + slugSegment
}

export const getCountryRoute = (
	trekOrClimb: Types.App.DataTypes.TrekOrClimb,
	continent: string,
	countryCode: string | undefined,
	countryName?: string,
): string => {
	const activitySegment = `/${trekOrClimb === 'TREK' ? 'trek' : 'climbing'}`
	const continentSegment = !!continent
		? '/' + sluggify(continent)
		: '/no-continent'
	const countrySegment =
		'/' +
		(!!countryCode
			? countryIsoCodeToSlugName(countryCode as string)
			: sluggify(countryName as string))

	return activitySegment + continentSegment + countrySegment
}

export const getCountryRouteFromName = (
	trekOrClimb: Types.App.DataTypes.TrekOrClimb,
	continent: string,
	countryName?: string,
): string => {
	const activitySegment = `/${trekOrClimb === 'TREK' ? 'trek' : 'climbing'}`
	const continentSegment = !!continent
		? '/' + sluggify(continent)
		: '/no-continent'
	const countrySegment = '/' + sluggify(countryName as string)

	return activitySegment + continentSegment + countrySegment
}

export const getContinentRoute = (
	trekOrClimb: Types.App.DataTypes.TrekOrClimb,
	continent: string,
): string => {
	const activitySegment = trekOrClimb === 'TREK' ? 'trek' : 'climbing'
	const continentSegment = !!continent ? sluggify(continent) : 'no-continent'

	return `/${activitySegment}/${continentSegment}`
}

export const getTrekEditRoute = (
	trek:
		| Types.DB.Models.TripAttributes
		| Types.DB.AdminPageTrek
		| { id: number },
) => {
	return `/admin/trips/edit/${trek.id}`
}

export const getArticleRoute = (article: Types.API.Article): string => {
	return `/articles/${article.slug}`
}

export const getArticleURL = (
	article: Types.API.Article,
	version: Types.VERSION,
): string =>
	`https://www.guided${
		version === Types.VERSION.TREKKING ? 'trekking' : 'peaks'
	}.com${getArticleRoute(article)}`

export const getTrekURL = (
	trekOrClimb: Types.App.DataTypes.TrekOrClimb,
	continent: string,
	countries: string[],
	slug: string,
	version: Types.VERSION,
) =>
	`https://www.guided${
		version === Types.VERSION.TREKKING ? 'trekking' : 'peaks'
	}.com${getTrekRoute(trekOrClimb, continent, countries, slug)}`

export const getContinentURL = (
	trekOrClimb: Types.App.DataTypes.TrekOrClimb,
	continent: string,
	version: Types.VERSION,
) =>
	`https://www.guided${
		version === Types.VERSION.TREKKING ? 'trekking' : 'peaks'
	}.com${getContinentRoute(trekOrClimb, continent)}`

export const getCountryURL = (
	trekOrClimb: Types.App.DataTypes.TrekOrClimb,
	continent: string,
	countryName: string,
	version: Types.VERSION,
) =>
	`https://www.guided${
		version === Types.VERSION.TREKKING ? 'trekking' : 'peaks'
	}.com${getCountryRouteFromName(trekOrClimb, continent, countryName)}`

export const getGuideRoute = (guide: Types.API.GuideCompany): string => {
	return `/guides/${guide.slug}`
}

export const getGuideProfileUrl = (version: Types.VERSION, slug: string) => {
	// return `https://www.guided${
	// 	version === Types.VERSION.TREKKING ? 'trekking' : 'peaks'
	// }.com/guides/${slug}`

	return (
		getBaseURL(
			version === Types.VERSION.TREKKING
				? Types.App.DataTypes.TrekOrClimb.TREK
				: Types.App.DataTypes.TrekOrClimb.CLIMB,
		) + `/guides/${slug}`
	)
}

export const getTrekImageAltText = (
	trip: Types.DB.Models.TripAttributes,
	keyword?: string,
) => {
	const { name } = trip
	const { alternativeNames, countries } = trip.jsonData

	const formattedCountries = (countries ?? [])
		.map((isoCode) => iso3311a2.getCountry(isoCode.toUpperCase()))
		.join(' / ')

	// todo: alternativeNames, to array or not
	// return (
	// 	(keyword ? keyword + ' ' : '') +
	// 	name +
	// 	((alternativeNames || []).length > 0
	// 		? ` (${alternativeNames?.join('/')})`
	// 		: '') +
	// 	`. ${formattedCountries}.`
	return (
		(keyword ? keyword + ' ' : '') +
		name +
		(!alternativeNames ? ` (${alternativeNames})` : '') +
		`. ${formattedCountries}.`
	)
}

// todo: obsolete?
export const distanceOrHeight = (
	trek: Types.DB.Models.TripAttributes,
	unit: string,
) => {
	const { trekOrClimb, highLevelInfo } = trek.jsonData
	const isTrekNotPeak = trekOrClimb === 'TREK'

	return isTrekNotPeak
		? highLevelInfo?.distances &&
		  (highLevelInfo?.distances?.total?.distance ?? []).length > 0
			? formatDistance(highLevelInfo.distances.total.distance, unit)
			: undefined
		: highLevelInfo?.climb?.maxElevation
		? formatHeight([highLevelInfo.climb.maxElevation], unit)
		: undefined
}

export const distanceOrHeightNew = (
	distances: [number, number] | [number] | [] | undefined,
	height: number | undefined,
	unit: string,
	trekOrClimb: Types.App.DataTypes.TrekOrClimb,
) => {
	const isTrekNotPeak = trekOrClimb === 'TREK'

	return isTrekNotPeak
		? (distances ?? []).length > 0
			? formatDistance(distances ?? [], unit)
			: undefined
		: height
		? formatHeight([height], unit)
		: undefined
}

export const cheapestPrice = (
	trip: Types.DB.Models.TripAttributes,
): number | undefined => {
	const cheapestPrice = (trip.jsonData?.guidedLinks ?? []).reduce(
		(
			minItem: Types.Trek.GuideLink | null,
			currentItem: Types.Trek.GuideLink,
		) => {
			if (minItem === null || currentItem.cost < minItem.cost) {
				return currentItem
			} else {
				return minItem
			}
		},
		null,
	)
	return cheapestPrice?.cost || undefined
}

export const lengthInDays = (trip: Types.DB.Models.TripAttributes) => {
	const { trekOrClimb, highLevelInfo } = trip.jsonData
	const isTrekNotPeak = trekOrClimb === 'TREK'

	return isTrekNotPeak
		? highLevelInfo?.distances?.total?.days &&
		  (highLevelInfo?.distances?.total?.days ?? []).length > 0
			? formatNumberOrRange(highLevelInfo.distances.total.days)
			: undefined
		: highLevelInfo?.climbRoute?.howManyDaysTotal &&
		  (highLevelInfo?.climbRoute?.howManyDaysTotal ?? []).length > 0
		? formatNumberOrRange(highLevelInfo?.climbRoute?.howManyDaysTotal)
		: undefined
}

export const lowestHighestPriceRange = (
	guideLinks: Types.Trek.GuideLink[],
): { lowest: number; highest?: number } | undefined => {
	if (guideLinks.length === 0) {
		return undefined
	}

	let lowest = guideLinks[0].cost
	let highest = guideLinks[0].cost

	for (let i = 1; i < guideLinks.length; i++) {
		const cost = guideLinks[i].cost

		if (cost < lowest) {
			lowest = cost
		}

		if (cost > highest) {
			highest = cost
		}
	}

	if (lowest === highest) {
		return { lowest, highest: undefined }
	}

	return { lowest, highest }
}

export const formatMonthNumbers = (months: number[]): string[] => {
	// Sort the array and remove duplicates
	months = Array.from(new Set(months)).sort((a, b) => a - b)

	// Check if array contains both December and January
	const containsDecJan = months.includes(1) && months.includes(12)

	// Function to get month name
	const getMonthName = (month: number): string => {
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		]
		return monthNames[month - 1]
	}

	// Helper function to format a group
	const formatGroup = (group: number[]): string => {
		if (group.length === 1) {
			return getMonthName(group[0])
		} else if (group.length === 2) {
			return `${getMonthName(group[0])} and ${getMonthName(group[1])}`
		} else {
			return `${getMonthName(group[0])} to ${getMonthName(
				group[group.length - 1],
			)}`
		}
	}

	// Group consecutive months, considering the wrap-around from December to January
	const groupedMonths: number[][] = []
	let currentGroup: number[] = [months[0]]

	for (let i = 1; i < months.length; i++) {
		if (months[i] === (currentGroup[currentGroup.length - 1] % 12) + 1) {
			currentGroup.push(months[i])
		} else {
			groupedMonths.push([...currentGroup])
			currentGroup = [months[i]]
		}
	}
	groupedMonths.push(currentGroup)

	// Handle the wrap-around from December to January
	if (containsDecJan && groupedMonths.length > 1) {
		if (
			groupedMonths[0][0] === 1 &&
			groupedMonths[groupedMonths.length - 1].includes(12)
		) {
			const lastGroup = groupedMonths.pop() || []
			groupedMonths[0] = [...lastGroup, ...groupedMonths[0]]
		}
	}

	// Format each group
	const formattedGroups = groupedMonths.map(formatGroup)

	// Join formatted groups
	return formattedGroups
}

export const getColorByHeight = (height: number): string => {
	if (height > 8000) {
		return '#4B0082' // Deep Indigo for extreme heights
	} else if (height > 7500) {
		return '#800080' // Purple for very high peaks
	} else if (height > 7000) {
		return '#B22222' // Crimson for high-altitude peaks
	} else if (height > 6500) {
		return '#FF4500' // Orange-Red for high peaks
	} else if (height > 6000) {
		return '#FFA500' // Orange for prominent peaks
	} else if (height > 5000) {
		return '#FFD700' // Gold for moderately high peaks
	} else if (height > 4000) {
		return '#3CB371' // Medium Sea Green for lower high-altitude
	} else if (height > 3000) {
		return '#00FA9A' // Medium Spring Green for mid-range peaks
	} else if (height > 2000) {
		return '#00CED1' // Dark Turquoise for lower mid-range
	} else {
		return '#4682B4' // Steel Blue for low altitudes
	}
}
